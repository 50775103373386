@font-face {
  font-family: 'Messina Sans';
  font-weight: 100 500;
  src: local('Messina Sans'),
    url('./fonts/MessinaSans-Regular.woff2') format('woff2'),
    url('./fonts/MessinaSans-Regular.woff') format('woff'),
    url('./fonts/MessinaSans-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Messina Sans';
  font-weight: 600 900;
  src: local('Messina Sans'),
    url('./fonts/MessinaSans-SemiBold.woff2') format('woff2'),
    url('./fonts/MessinaSans-SemiBold.woff') format('woff'),
    url('./fonts/MessinaSans-SemiBold.ttf') format('truetype');
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
